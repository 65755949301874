import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import Header from "../components/Layout/Header"
import MarkerHead from "../components/Atomic/Typography/MarkerHead"
import Pagination from "../components/Atomic/Pagination"
import Seo from "../components/Layout/Seo"
import GoldenArea from "../components/Atomic/Post/GoldenArea"
import ImageFromNewt from "../components/Atomic/ImageFromNewt"
import GrayBadge from "../components/Atomic/Badge/GrayBadge"

export default function lifestyleArchive({ data, pageContext }) {
  return (
    <div>
      <Layout>
        <Header />
        <div>
          <MarkerHead text={"Life Style"} />
          <section className="w-full bg-white py-16 mb-16">
            <div className="basic-wrapper grid gap-6 md:grid-cols-3 xl:grid-cols-4">
              {data.allNewtLifestyle.edges.map((edge, index) => {
                const data = edge.node
                return (
                  <div>
                    <Link to={`/life-style/${data.slug}`}>
                      <ImageFromNewt
                        src={data.eyecatch.src}
                        alt={data.title}
                        className={
                          "aspect-[3/2] object-cover rounded-xl mb-2  custom-shadow-md"
                        }
                      />
                    </Link>
                    <GrayBadge text={data.type} className={"font-alphabet"} />
                    <h2 className="text-base pt-2">{data.title}</h2>
                  </div>
                )
              })}
            </div>
          </section>
        </div>
        <Pagination
          totalCount={data.allNewtLifestyle.totalCount}
          current={pageContext.current}
        />
        <GoldenArea />
      </Layout>
    </div>
  )
}

export const query = graphql`
  query ($limit: Int!, $skip: Int!) {
    allNewtLifestyle(limit: $limit, skip: $skip) {
      edges {
        node {
          eyecatch {
            src
          }
          title
          slug
          type
        }
      }
      totalCount
    }
  }
`

export const Head = () => <Seo title="Life Style一覧" />
