import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GetRecipeFromAll from "./GetRecipeFromAll"

export default function GoldenArea() {
  const selectionData = useStaticQuery(graphql`
    query goldenArea {
      allNewtSelection {
        edges {
          node {
            mostViewed {
              selection_recipe {
                recipeTitle
              }
            }
          }
        }
      }
    }
  `)
  const mostViewed = selectionData.allNewtSelection.edges
  return (
    <div className="w-full bg-omcGold pb-16">
      <h2 className="must-read-title">
        <span className="max-w-screen-md mx-auto block font-alphabet text-white text-[clamp(42px,13vw,80px)] md:text-5xl xl:text-7xl text-center md:text-left z-10 relative">
          Most Viewd
        </span>
      </h2>
      <div className="basic-wrapper">
        <div className="grid grid-cols-2 md:grid-cols-4 mt-8 max-w-screen-md mx-auto gap-3">
          <GetRecipeFromAll search={mostViewed} />
        </div>
      </div>
    </div>
  )
}
