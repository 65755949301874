import React from 'react'
import { Link } from 'gatsby'

export default function Pagination({totalCount, current}) {
    const PER_PAGE = 9

    const range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i)
    return (
      <ul className='flex flex-wrap gap-4 justify-center max-w-screen-md m-auto px-4 mb-20'>
        {range(1, Math.ceil(totalCount / PER_PAGE)).map((number, index) => (
          <li key={index} className={`w-8 h-8  border relative rounded-full overflow-hidden shadow-materialHover ${current === number ? "bg-omcBlack text-white border-omcBlack":null}`}>
            <Link to={`/recipe/page/${number}`} className="block w-full h-full"><span className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 leading-none text-center'>{number}</span></Link>
          </li>
        ))}
      </ul>
    )
}